<template>
  <div class="cash-payment fill-height">
    <v-row class="fill-height" style="margin-top: 0 !important;">
      <left-side-image/>
      <v-col cols="12" md="7">
        <nav-bar-page/>
        <v-container>
          <v-row justify="center" class="mt-0">
            <v-col cols="12" md="10" class="px-4">
              <v-img max-height="88"
                     class="my-1"
                     contain
                     src="@/assets/img/dinero.png"/>
              <h1 class="head mt-6 text-center today-light text-uppercase primary--text">
                PAGO EN EFECTIVO
              </h1>
              <p class="sub-title text-left primary--text line-height-2">
                ¡Tu referencia de pago ha sido generada con éxito! Recuerda realizar el pago dentro de la fecha de
                vigencia para garantizar su validez. Te hemos enviado los detalles a tu correo {{ email }}
              </p>

              <h1 class="pay-title today-medium primary--text mb-4">Detalles de pago:</h1>
              <v-card class="text-center py-4 px-6" color="grey lighten-4" flat style="border-radius: 15px;">
                <v-row>
                  <v-col class="pa-5">
                    <ul class="payment-details">
                      <li>
                        <div>
                          <span class="primary--text today-regular pay-ref mb-2">Referencia de pago:</span>
                          <span class="pay-num">{{ paymentReference.payment_reference }}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span class="primary--text today-regular pay-ref mb-2">Vigencia:</span>
                          <span class="pay-num">{{ paymentReference.payment_limit }}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span class="primary--text today-regular pay-ref mb-2">Entidad financiera:</span>
                          <span class="pay-num">DAVIVIENDA</span>
                        </div>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card>
              <v-layout justify-center pt-5>
                <v-card-actions>
                  <v-btn
                      style="min-width: 240px"
                      :loading="loading"
                      :disabled="loading"
                      @click="goHome"
                      color="primary"
                      rounded
                      class="px-12 text-capitalize vardi-button"
                      elevation="0">
                    FINALIZAR
                  </v-btn>
                </v-card-actions>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage.vue";
import NavBarPage from "@/components/Layouts/NavBarPage.vue";
import {formatCurrency} from "@/helpers/constants";

export default {
  name: "CashPayment",
  components: {NavBarPage, LeftSideImage},
  data() {
    return {
      paymentReference: null,
      loading: false,
      snackbar: false,
      timeout: 3000,
      messageSnackbar: '',
      email: null,
    }
  },
  created() {
    this.paymentReference = JSON.parse(localStorage.getItem('payment_reference'));
    const user = JSON.parse(localStorage.getItem('user'));
    this.email = user.email;
  },
  mounted() {
  },
  methods: {
    goHome() {
      localStorage.clear();
      this.$router.push('/');
    },
    formatCurrency: formatCurrency
  }
}
</script>
<style lang="scss">


.head {
  font-size: 2.1em !important;
  line-height: 1 !important;
  margin-block-end: .5em !important;
}

.sub-title {
  font-size: 1.6em !important;
  line-height: 1.2 !important;
  margin-block-end: 1em !important;
}

.pay-title {
  font-size: 1.7em !important;
  line-height: 1.2 !important;
  margin-block-end: 1em !important;
  font-weight: normal;
}

.payment-details {
  li::marker {
    color: var(--v-primary-base);
    padding-right: 10px;
  }

  li div {
    margin-left: 10px;
    display: flex;
    align-items: baseline;

    span.pay-ref {
      font-size: 1.5em;
      line-height: 1.5;
    }

    span.pay-num {
      margin-left: 10px;
      font-size: 1.2em;
      line-height: 1.5;
      font-weight: bold;
    }
  }
}
</style>