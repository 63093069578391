<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">
        <nav-bar-page/>
        <v-container>
          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Pago
              </div>
            </v-col>
          </v-row>
          <template v-if="pageLoading">
            <div class="d-flex justify-center mt-6">
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>
          </template>
          <template v-else>
            <v-row justify="center">
              <v-col cols="12" md="10" class="px-4">
                <v-card class="pa-5" color="grey lighten-4" flat style="border-radius: 15px;">
                  <v-row justify="center" class="px-4">
                    <v-col cols="12" md="6" class="pt-7">
                      <v-row justify="center">
                        <v-col cols="3">
                          <v-img max-height="60"
                                 class="my-1"
                                 contain
                                 src="@/assets/img/valor.png"
                          />
                        </v-col>
                        <v-col cols="7">
                          <h2 class="today-light text-uppercase primary--text">{{ policyLabel }}</h2>
                          <h1 class="mb-0 today-light">{{ policyValue }}</h1>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="3" class="justify-end">
                          <v-img max-height="60"
                                 class="my-1"
                                 contain
                                 src="@/assets/img/calendario.png"
                          />
                        </v-col>
                        <v-col cols="7">
                          <h2 class="today-light text-uppercase primary--text">{{ policyDate }}</h2>
                          <h1 class="mb-0 today-light">{{ policyDateValue }}</h1>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="3">
                          <v-img max-height="60"
                                 class="my-1"
                                 contain
                                 src="@/assets/img/seguro.png"
                          />
                        </v-col>
                        <v-col cols="7">
                          <h2 class="today-light text-uppercase primary--text">{{ policyType }}</h2>
                          <h1 class="mb-0 today-light">{{ policyTypeValue }}</h1>
                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col cols="12" md="6" style="position: relative;">
                      <div class="with-border">
                        <v-radio-group v-model="paymentType" class="mt-3 mb-0" style="padding-top: 4px;">
                          <div class="mb-4">
                            <h2 class="pl-8 primary--text today-light mb-4">PAGO MÍNIMO DE LA CUOTA*</h2>
                            <v-radio value="min"
                                     key="1"
                                     class="mb-0">
                              <template v-slot:label>
                                <h3 class="mb-0 today-light" style="font-size: 2.2em; color: #000;">
                                  {{ formatCurrency(minAmount) }}</h3>
                              </template>
                            </v-radio>
                          </div>
                          <div class="mb-4" style="padding-top: 2px;">
                            <h2 class="pl-8 primary--text today-light">PAGAR OTRO VALOR</h2>
                            <div class="mt-2">
                              <v-radio value="other"
                                       key="2"
                                       class="">
                                <template v-slot:label>
                                  <currency-input
                                      :large="true"
                                      :rules="paymentType === 'other' ? [rules.min, rules.max] : []"
                                      :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: true,
                                    autoDecimalDigits: false,
                                    autoSign: true,
                                    useGrouping: true,
                                    accountingSign: false
                                  }"
                                      v-model.number="otherAmount"
                                      :disabled="false"
                                      class="pt-5"
                                      v-on:change="calculateFee($event)"/>
                                </template>
                              </v-radio>
                            </div>
                          </div>
                          <p class="text-caption text-center primary--text line-height-1">
                            *Para activar tu póliza, es necesario realizar el primer pago de la cuota.
                            Este paso es importante para asegurar que tu cobertura entre en vigor
                            de manera inmediata.
                          </p>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-btn
                                  style="font-size: .7em;"
                                  width="100%"
                                  rounded
                                  outlined
                                  @click="payDialog = true"
                                  color="primary"
                                  class="text-capitalize today-medium"
                                  elevation="0">
                                PLAN DE PAGO
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-btn
                                  style="font-size: .7em;"
                                  width="100%"
                                  rounded
                                  @click="dialog = true"
                                  color="primary"
                                  class="text-capitalize today-medium"
                                  elevation="0">
                                PAGAR
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-col>
    </v-row>

    <payment-plan :pay-plan="payPlan"
                  :total-value-policy="valueFinance"
                  :fee-number="feeNumber"
                  :dialog="payDialog"
                  @close="payDialog = false"/>

    <v-dialog
        v-model="dialog"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/enviar.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">¡SOLICITUD ENVIADA!</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">Tú solicitud para la financiación de la
          poliza para vehículo ha sido aceptada.</h3>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 text-capitalize today-bold vardi-button"
              @click="goHome"
              elevation="0"
          >
            CONTINUAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      {{ messageSnackbar }}
    </v-snackbar>
    <v-dialog
        v-model="dialog"
        max-width="430"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 pb-8 pl-8 pr-8 text-center">
        <h2 class="my-3 mb-4 today-medium justify-center primary--text dialog-title" style="padding-top: 15px">MÉTODO DE
          PAGO</h2>
        <h3 class="text-justify primary--text today-light line-height-1 px-4 dialog-subtitle mb-4">
          Selecciona un método de pago para
          pagar la cuota de tú póliza
        </h3>

        <v-card-actions class="justify-center">
          <v-row>
            <v-col cols="6">
              <button
                  :disabled="loadingCardPayment || loadingCashPayment"
                  @click.stop="paymentRequest('cash')"
                  class="mx-auto pa-4 pay-button"
                  style="width: 100%; border: 1px solid #00205B !important; border-radius: 12px;">
                <v-img
                    v-if="!loadingCashPayment"
                    max-width="50"
                    class="mb-4 pa-5 home-image mx-auto image"
                    contain
                    src="@/assets/img/dinero.png"
                ></v-img>
                <v-progress-circular
                    v-else
                    class="pa-5 mb-4"
                    :size="50"
                    color="primary"
                    indeterminate
                />

                <p class="today-medium text-center mb-0 primary--text" style="font-size: 1em; line-height: 1;">
                  PAGO EN EFECTIVO
                </p>

              </button>
            </v-col>
            <v-col cols="6">
              <button
                  :disabled="loadingCardPayment || loadingCashPayment"
                  @click.stop="paymentRequest('card')"
                  class="mx-auto pa-4  pay-button"
                  style="border: 1px solid #00205B !important; border-radius: 12px;">
                <v-img
                    v-if="!loadingCardPayment"
                    max-width="50"
                    class="mb-4 pa-5 home-image mx-auto image"
                    contain
                    src="@/assets/img/pagar.png"
                ></v-img>
                <v-progress-circular
                    v-else
                    class="pa-5 mb-4"
                    :size="50"
                    color="primary"
                    indeterminate
                />

                <p class="today-medium text-center mb-0 primary--text" style="font-size: 1em; line-height: 1;">
                  PAGO CON TARJETA / PSE
                </p>

              </button>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {addDays, constants, formatCurrency, formatDecimal} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/CurrencyInput";
import PaymentPlan from "@/components/PaymentPlan.vue";

export default {
  name: 'Payment',
  components: {PaymentPlan, LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      dialog: false,
      pageLoading: true,
      loadingCardPayment: false,
      loadingCashPayment: false,
      expires_in: 60,
      countdown: '',
      minAmount: 0,
      urlPayment: '',
      otherAmount: '',
      snackbar: false,
      timeout: 2000,
      messageSnackbar: '',
      settings: null,
      request: null,
      policyType: '',
      policyTypeValue: '',
      policyDate: '',
      policyDateValue: '',
      policyLabel: '',
      policyValue: '',
      paymentType: 'min',
      payDialog: false,
      payPlan: [],
      feeValue: 0,
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      creditInsurance: 0,
      ivaValue: 0,
      iva: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 0,
      totalValuePolicy: 0,
      discountValue: 0,
      valueFinance: 0,
      feeNumber: 2,
      insuranceRate: 0,
      rate: 0,
      rules: {
        min: () => this.otherAmount > this.minAmount || 'Este valor debe ser mayor que el pago mínimo' + ' - ' + this.formatCurrency(this.minAmount),
        max: () => this.otherAmount <= this.valueFinance || 'Este valor no debe ser mayor que el valor de la poliza',
      },
    }
  },
  mounted() {
  },
  methods: {
    goHome() {
      this.dialog = false;
      this.$router.push('/');
    },
    newCalculateFee(valueFinance = null, discount = false) {
      this.payPlan = [];
      const date = new Date();
      const _date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      console.log(valueFinance, _date);
      api.post('https://tefio-vardi.bio.credit/calcularcuota', {
        data: {
          fecha: _date,
          valor: valueFinance ? valueFinance : this.valueFinance,
          plazo: discount ? this.feeNumber - 1 : this.feeNumber,
          tasa: this.insuranceRate,
          portafolio_id: 10040,
          idvardi: this.request.id
        }
      })
          .then(response => {
            const payPlan = response.data.plan_pagos;
            if (discount) {
              payPlan.unshift({
                nro_cuota: 0,
                cuota: this.otherAmount,
                saldo_final: this.valueFinance - this.otherAmount,
                abono_capital: this.otherAmount,
                interes_corriente: 0,
              });
            }
            this.payPlan = response.data.plan_pagos.map(item => {
              item.cuota = formatDecimal(item.cuota);
              item.saldo_final = formatDecimal(item.saldo_final);
              item.abono_capital = formatDecimal(item.abono_capital);

              if (discount) {
                if (item.nro_cuota === 1) {
                  item.interes_corriente = valueFinance * (this.rate / 100);
                }
                item.nro_cuota++;
              }
              return item;
            });
            this.payPlan.sort((a, b) => a.nro_cuota - b.nro_cuota);
          }).catch(function (error) {
        try {
          let status = error.response.status;
          if (status === 401) {
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (const [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
        } catch (e) {
          console.log(e);
        }
      }.bind(this))
    },
    calculateFee(event) {
      let valueFinance = 0;
      if (this.otherAmount > this.minAmount) {
        valueFinance = this.valueFinance - this.otherAmount;
        this.newCalculateFee(valueFinance, true);
      }
    },
    formatCurrency: formatCurrency,
    paymentRequest(source) { // source: card | cash
      if (this.paymentType === 'other') {
        if (this.otherAmount > this.minAmount && this.otherAmount <= this.valueFinance) {
          if (source === 'card') {
            this.generateCardPayment();
          } else {
            this.generateCashPayment();
          }
        } else {
          this.snackbar = true;
          this.messageSnackbar = "Revisa el valor para continuar"
        }
      } else {
        console.log('paymentRequest min', this.minAmount);
        if (source === 'card') {
          this.generateCardPayment();
        } else {
          this.generateCashPayment();
        }
      }
    },
    generateCardPayment() {
      this.loadingCardPayment = true;
      console.log(this.paymentType);
      api.post(constants.endPoints.paymentRequest, {
        user_request_id: this.request.id,
        payment_type: this.paymentType,
        other_amount: this.otherAmount,
      }, true)
          .then((response) => {
            console.log('paymentRequest', response);
            this.loadingCardPayment = false;
            this.urlPayment = response.data.data[0].payment_url;
            window.open(this.urlPayment, "_self");
          }, (error) => {
            console.log('paymentRequest', error);
            this.loadingCardPayment = false;
            this.snackbar = true;
            this.messageSnackbar = "Se ha producido un error"
          });
    },
    generateCashPayment() {
      this.loadingCashPayment = true;
      const date = addDays(new Date(), 1);
      api.post(constants.endPoints.paymentRequestCash, {
        user_request_id: this.request.id,
        route: 'P',
        payment_limit: date.toISOString().slice(0, 10),
        max_pay: this.valueFinance,
        min_pay: this.otherAmount > this.minAmount ? this.otherAmount : (+this.minAmount).toFixed(0),
      }, true)
          .then(({data}) => {
            localStorage.setItem('payment_reference', JSON.stringify(data));
            this.loadingCashPayment = false;
            this.$router.push('/cash-payment');
          }, (error) => {
            console.log('paymentRequest', error);
            this.loadingCashPayment = false;
            this.snackbar = true;
            this.messageSnackbar = "Se ha producido un error"
          });
    }
  },
  created: function () {
    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');
    api.post(constants.endPoints.startPayment, {
      user_request_id: this.request.id,
    }, true)
        .then(response => {
          let dataResponse = response.data.data;
          this.minAmount = dataResponse.min_payment;
          this.policyType = dataResponse.values[0].name;
          this.policyTypeValue = dataResponse.values[0].value;
          this.policyDate = dataResponse.values[1].name;
          this.policyDateValue = dataResponse.values[1].value;
          this.policyLabel = dataResponse.values[2].name;
          this.policyValue = dataResponse.values[2].value;
          this.admin = parseInt(dataResponse.administration, 10);
          this.valueFinance = parseInt(dataResponse.total, 10);
          this.feeNumber = parseInt(dataResponse.fee_number, 10);
          this.insuranceRate = dataResponse.insurance_rate;
          this.rate = parseFloat(dataResponse.rate);
          this.iva = parseFloat(dataResponse.iva);
          this.creditInsurance = parseFloat(dataResponse.credit_insurance);
          this.creditStudy = parseInt(dataResponse.credit_study, 10);
          this.pageLoading = false;
          this.newCalculateFee();
        }).catch(function (error) {
      try {
        let status = error.response.status;
        if (status == 401) {
          locals.clearLocal();
          this.$router.push('/');
        }
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
      } catch (e) {
        console.log(e);
      }

    }.bind(this))

  },
  watch: {
    paymentType: function (value) {
      if (value === 'min') {
        this.newCalculateFee();
        this.otherAmount = 0;
      }
    }
  }
}
</script>

<style lang="scss">
.v-input input {
  height: 49px !important;
  max-height: 49px !important;
}

.v-stepper__label {
  font-size: 13px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

h1 {
  font-size: 1.4em !important;
}

h2 {
  font-size: 1.2em !important;
  margin-block-end: 0 !important;
}

h2.dialog-title {
  font-size: 1.3em !important;
}

h3.dialog-subtitle {
  font-size: 1.6em !important;
}

.with-border::after {
  border-left: 0.5px solid #00205B !important;
  content: "";
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: -10px;
}

.pay-button {
  text-transform: capitalize;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 0 !important;

  &:disabled {
    opacity: 0.5;
  }

  &:hover:enabled {
    background-color: #00205B !important;
    color: #fff !important;

    p {
      color: #fff !important;
    }

    .image {
      filter: brightness(0) invert(1);
    }
  }
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>
