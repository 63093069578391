<template>
  <div class="contact-data fill-height">
    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">
        <nav-bar-page/>
        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>

            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Simulador
              </div>
            </v-col>

          </v-row>
          <template v-if="!loading">
            <v-row v-if="simulatorStatus" justify="center" class="px-0">
              <v-row justify="center">
                <v-col cols="10">
                  <v-form
                      ref="form"
                      lazy-validation>
                    <v-row>
                      <v-col cols="12" md="5">
                        <h2 class="primary--text today-regular">
                          Valor de la póliza
                        </h2>
                        <span class="grey--text text-sm-body-2 today-medium">Desde $ 100.000</span>
                        <currency-input
                            :disabled="true"
                            v-model="totalValuePolicy"
                            :options="{
                            locale: 'en-US',
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            hideCurrencySymbolOnFocus: true,
                            hideGroupingSeparatorOnFocus: false,
                            hideNegligibleDecimalDigitsOnFocus: false,
                            autoDecimalDigits: false,
                            autoSign: false,
                            useGrouping: true,
                            accountingSign: true
                          }"/>
                      </v-col>
                      <v-col cols="12" md="5" offset-md="2">
                        <h2 class="primary--text today-regular">
                          Plazo de financiación
                        </h2>
                        <span class="grey--text">De 2 a {{ this.maxFeeNumber }} meses</span>
                        <v-select
                            :items="items"
                            label="Cuotas"
                            required
                            outlined
                            single-line
                            color="primary"
                            v-model="feeNumber"
                            v-on:change="newCalculateFee()"
                            class="text-h5 select-vardi"
                            align="center"></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-row>
            <v-row justify="center" class="mb-5">
              <v-col cols="10" class="pa-0">
                <v-card class="px-5 py-3"
                        color="grey lighten-4 rounded-lg"
                        outlined>
                  <v-row>
                    <v-col cols="12" md="7" order="2" order-md="1">
                      <h1 class="today-light primary--text mb-3">Tu cuota mensual sería de:</h1>
                      <h2 class="today-regular text-h3 mb-6">{{ formatCurrency(firstFeeValue) }}</h2>
                      <v-row style="padding-top: 4px;">
                        <v-col cols="12" md="5" order="2" order-md="1">
                          <v-btn
                              color="primary"
                              class="text-capitalize today-medium"
                              elevation="0"
                              block
                              rounded
                              :loading="loadingFinance"
                              @click="registerCellphone">
                            FINANCIACIÓN
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-btn
                              @click="payDialog = true"
                              color="white"
                              class="text-capitalize primary--text today-medium"
                              elevation="0"
                              outlined
                              rounded
                              block>
                            PLAN DE PAGO
                          </v-btn>

                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="5" order="1" order-md="2">
                      <h6 class="text-list today-light mb-1 primary--text" style="margin-top: 7px;">TASA FIJA MES
                        VENCIDO</h6>
                      <h2 class="today-light text-big mb-1">{{ rate }} {{ rateType }}</h2>
                      <h6 class="text-list today-light mb-1 primary--text">TASA EFECTIVA ANUAL</h6>
                      <h2 class="today-light text-big">{{ insuranceRate }} E.A.</h2>
                    </v-col>
                    <v-col cols="12" order="3">
                      <p class="text-caption text-center primary--text line-height-1">
                        *La información presentada es un cálculo aproximado basado en la información suministrada y no
                        constituyente de otorgamiento de crédito, para el cual se deberá realizar el
                        estudio de la solicitud de crédito acorde con las políticas de la aseguradora. Algunas
                        condiciones
                        de crédito podrán variar sin previo aviso
                      </p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="!simulatorStatus">
              <v-col cols="12">
                <h2 class="primary--text text-center mb-0 today-light line-height-1">{{ simulatorMessage }}</h2>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <div class="d-flex justify-center mt-6">
              <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>

          </template>
        </v-container>
      </v-col>
    </v-row>

    <payment-plan :pay-plan="payPlan"
                  :total-value-policy="totalValuePolicy"
                  :fee-number="feeNumber"
                  :dialog="payDialog"
                  @close="payDialog = false"/>
    <v-dialog
        v-model="registerCellphoneDialog"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">
        <h2 style="padding-top: 15px" class="my-3 today-medium justify-center primary--text">REGISTRAR CELULAR</h2>
        <h3 class="text-justify primary--text today-light line-height-1 px-4">
          Te enviaremos un código de verificación por SMS al número de celular que registres
        </h3>

        <div class="px-10 mt-6" v-if="!codeSent">
          <v-form
              ref="form"
              lazy-validation>

            <v-text-field
                v-model="cellphone"
                :rules="[rules.required, rules.counterMax, rules.counterMin]"
                type="number"
                label="Celular"
                maxlength="10"
                outlined
                dense
                color="primary"
                class="input-field"></v-text-field>

            <v-layout justify-center>
              <v-card-actions>
                <v-btn
                    style="min-width: 240px"
                    v-if="!codeSent"
                    :loading="loadingCellphone"
                    :disabled="loadingCellphone"
                    color="primary"
                    class="px-10 mt-0 text-capitalize today-bold vardi-button"
                    @click="sendCellphone"
                    elevation="0"
                    rounded
                >
                  CONTINUAR
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

        <div class="px-4 mt-2" v-if="codeSent">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="text"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"></v-text-field>
              </v-col>

            </v-row>

            <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará
              un nuevo código en 00:{{ countdown }}</p>

            <p class="primary--text text-center" v-if="reSendCode">
              <a href="#" @click="sendCellphone()" class="text-center today-regular">Reenviar código</a>
            </p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">

          <v-btn
              style="min-width: 240px"
              v-if="codeSent"
              :loading="loadingCellphone"
              :disabled="loadingCellphone"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCode"
              elevation="0"
              rounded
          >
            ENVIAR CÓDIGO
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants, formatCurrency, formatDecimal} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/SimulatorCurrencyInput.vue";
import PaymentPlan from "@/components/PaymentPlan.vue";

export default {
  name: 'Policy',
  components: {PaymentPlan, LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      loading: true,
      cellphone: '',
      payDialog: false,
      registerCellphoneDialog: false,
      loadingCellphone: false,
      loadingFinance: false,
      codeSent: false,
      items: [2],
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      creditInsurance: 0,
      ivaValue: 0,
      iva: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 0,
      totalValuePolicy: 0,
      discountValue: 0,
      valueFinance: 0,
      feeNumber: 2,
      minFeeNumber: 0,
      maxFeeNumber: 0,
      typeFeeNumber: '',
      insuranceRate: 0,
      rate: 0,
      rateType: '',
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      request: null,
      insurancePolicyId: null,
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      simulatorStatus: true,
      simulatorMessage: '',
      payPlan: [],
      activationKeyFields: [
        {length: 1, value: ''},
        {length: 1, value: ''},
        {length: 1, value: ''},
        {length: 1, value: ''},
        {length: 1, value: ''},
        {length: 1, value: ''}
      ],
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      reSendCode: false,
    }
  },
  mounted() {
  },
  methods: {
    registerCellphone() {
      this.loadingFinance = true;
      api.get(constants.endPoints.phoneIsRegistered, true)
          .then(response => {
            let dataResponse = response.data;
            if (dataResponse.status) {
              this.createRequest();
            } else {
              this.registerCellphoneDialog = true;
              this.loadingFinance = false;
            }
          }).catch(function (error) {
        try {
          let status = error.response.status;
          if (status === 401) {
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))
    },
    sendCellphone: function () {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.loadingCellphone = true;

        api.post(constants.endPoints.registerPhone, {
          cellphone: this.cellphone
        }, true)
            .then((response) => {
              console.log(response);
              this.request.id = response.data.data.id;

              // Se inicia la cuenta atras
              this.startCountDown();
              this.loadingCellphone = false;
              this.codeSent = true;

            }, (error) => {
              console.log('post', error);
            });
      }
    },
    sendValidateCode: function () {
      this.loadingCellphone = true;

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      console.log('token', token);

      if (token.length === 6) {
        api.post(constants.endPoints.validatePhone, {
          cellphone: this.cellphone,
          token: token
        }, true)
            .then((response) => {
              console.log(response);
              let dataResponse = response.data;
              if (dataResponse.status) {
                this.createRequest();
              } else {
                this.loadingCellphone = false;
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }
            }).catch(() => {
          this.loadingCellphone = false;
          this.snackbarMessage = 'Error al enviar el código';
          this.snackbar = true;
        });
      } else {
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }
    },
    createRequest() {
      api.post(constants.endPoints.createRequest, {
        insurance_policy_id: this.insurancePolicyId,
        fee_number: this.feeNumber
      }, true)
          .then((response) => {
            this.loadingFinance = false;
            this.request.id = response.data.data.id;
            locals.setLocal('request', JSON.stringify(this.request));
            this.$router.push('/formulario');
          }, (error) => {
            console.log('post', error);
          });
    },
    newCalculateFee() {
      this.valueFinance = parseInt(this.valueFinance, 10);
      const date = new Date();
      const _date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      console.log(_date);
      api.post('https://tefio-vardi.bio.credit/calcularcuota', {
        data: {
          fecha: _date,
          valor: this.valueFinance,
          plazo: this.feeNumber,
          tasa: this.insuranceRate,
          portafolio_id: 10040,
          idvardi: this.request.id
        }
      })
          .then(response => {
            console.log('calculo sigma', response, response.data.plan_pagos[0].cuota);
            this.feeValue = response.data.plan_pagos[0].cuota
            this.amortizedFee = this.feeValue;
            this.firstFeeValue = this.amortizedFee + this.creditStudy + this.insurance + this.ivaValue;
            this.othersFeeValue = this.amortizedFee + this.admin;
            this.payPlan = response.data.plan_pagos;
            this.payPlan = this.payPlan.map(item => {
              item.cuota = formatDecimal(item.cuota);
              item.saldo_final = formatDecimal(item.saldo_final);
              item.abono_capital = formatDecimal(item.abono_capital);
              return item;
            })
            this.payPlan.sort((a, b) => a.nro_cuota - b.nro_cuota);
          }).catch(function (error) {
        try {
          let status = error.response.status;
          if (status === 401) {
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (const [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
        }
      }.bind(this))
    },
    formatCurrency: formatCurrency,
    startCountDown: function () {
      this.countdown = this.expires_in;
      this.interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(this.interval);
          this.countdown = this.expires_in;
          this.reSendCode = true;
          //this.reSendSignatureOtp();
        } else {
          this.countdown -= 1;
          this.reSendCode = false;
        }
      }, 1000);
    },
    goNext(event) {
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    },
    handleActivationInput(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      if (this.activationKeyFields[index].value.length > maxlength) {
        event.preventDefault();
        this.activationKeyFields[index].value = value.slice(0, 6);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {
        }
        return;
      }

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          event.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        event.preventDefault();
      }
      console.log(event, index, maxlength, this.activationKeyFields);

    },
    getSimulatorService(insurancePolicyId) {
      this.insurancePolicyId = insurancePolicyId;
      api.get(constants.endPoints.simulator + '?insurance_policy_id=' + insurancePolicyId, true)
          .then(response => {
            this.loading = false;
            let simulator = response.data.data;
            this.simulatorStatus = response.data.status;
            this.simulatorMessage = response.data.message;

            if (this.simulatorStatus) {
              console.log('simulator', simulator);
              this.discountValue = simulator.discount;
              this.totalValuePolicy = simulator.total;
              this.valueFinance = parseInt(simulator.total_final, 10);
              this.insuranceRate = simulator.insurance_rate;
              this.rate = simulator.rate;
              this.rateType = simulator.rate_type;
              this.creditStudy = parseInt(simulator.credit_study, 10);
              this.admin = parseInt(simulator.administration, 10);
              this.creditInsurance = parseInt(simulator.credit_insurance, 10);
              this.iva = simulator.iva;

              this.minFeeNumber = simulator.min_fee_number;
              this.maxFeeNumber = simulator.max_fee_number;
              for (let i = parseInt(this.minFeeNumber); i <= parseInt(this.maxFeeNumber); i++) {
                this.items.push(i);
              }
              this.typeFeeNumber = simulator.type_fee_number;

              this.newCalculateFee();
            }

          });
    },
    reSendSignatureOtp() {
      api.post(constants.endPoints.reSendSignatureOtp, {
        user_request_id: this.request.id,
      }, true)
          .then(response => {
            let dataResponse = response.data.data;

            this.snackbarMessage = dataResponse.otp.message;
            this.snackbar = true;

            this.startCountDown();

            console.log('reSendSignatureOtp', dataResponse);

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if (status == 401) {
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))
    }
  },
  created: function () {
    this.insurancePolicyId = this.$route.params.insurancePolicyId;
    this.request = {};
    if (this.insurancePolicyId != null) {
      this.request.insurance_policy_id = this.insurancePolicyId;
      locals.setLocal('request', JSON.stringify(this.request));
      this.getSimulatorService(this.request.insurance_policy_id);
    } else {
      this.request = locals.getLocal('request');
      if (this.request) {
        this.getSimulatorService(this.request.insurance_policy_id);
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    activationKey() {
      let value = '';
      for (let field of this.activationKeyFields) {
        value += field.value;
      }
      return value;
    }
  },
}
</script>

<style lang="scss">
.text-big {
  font-size: 2em !important;
}

.header-pagos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

@media (max-width: 600px) {
  .header-pagos {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.table-striped {
  table {
    border-collapse: collapse;
  }

  table thead tr th {
    box-shadow: none !important;
  }

  table tr:nth-child(even) {
    background-color: #f6f6f6 !important;
  }

  table tr td {
    border: none !important;
  }
}

h1 {
  font-size: 1.8em !important;
}

h2 {
  font-size: 1.3em !important;
  margin-block-end: 0 !important;
}

.text-caption {
  font-size: 0.65em !important;
  padding: 0 !important;
}

.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}
</style>
