<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Pago
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="mt-4 px-4">

            <v-col class="text-center" cols="12" md="4">
              <v-img max-height="75"
                     class="my-1"
                     contain
                     :src="statusImage"
              ></v-img>
            </v-col>

            <v-col cols="12" class="mt-0 pt-0">
              <div class="mt-0 pt-0 primary--text">
                <h1 class="text-center today-regular">{{ statusTitle }}</h1>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <h2 class="primary--text text-center mb-0 today-light line-height-1">{{ statusMessage }}</h2>
            </v-col>

          </v-row>

          <v-layout justify-center class="mt-14">
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="goHome"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Finalizar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      {{ messageSnackbar }}
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {formatCurrency} from "@/helpers/constants";

export default {
  name: 'Payment',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      dialog: false,
      loading: false,
      snackbar: false,
      timeout: 2000,
      messageSnackbar: '',
      status: null,
      requestId: null,
      statusMessage: '',
      statusImage: '',
      statusTitle: '',
      dataStatus: [
        {
          status: 0,
          image: 'https://app-biocredit.s3.amazonaws.com/vardi/pendiente.png',
          message: 'La entidad financiera se encuentra procesando tú pago, en unos minutos te notificaremos el estado de la transacción',
          title: '¡PAGO PENDIENTE!'
        },
        {
          status: 1,
          image: 'https://app-biocredit.s3.amazonaws.com/vardi/check-mark.png',
          message: 'Hemos procesado exitosamente el pago de tu cuota',
          title: '¡PAGO EXITOSO!'
        },
        {
          status: 2,
          image: '',
          message: '',
          title: ''
        },
        {
          status: 3,
          image: '',
          message: '',
          title: ''
        },
        {
          status: 4,
          image: 'https://app-biocredit.s3.amazonaws.com/vardi/marca-x.png',
          message: 'Lo sentimos no fue posible procesar el pago, intentalo más tarde',
          title: '¡PAGO RECHAZADO!'
        },
        {
          status: 5,
          image: '',
          message: '',
          title: ''
        },
      ]

      /* STATUS
      0	Pending
      1	Approved
      2	Cancelled
      4	Rejected
      5	Expired
      */
    }
  },
  created: function () {
    this.status = this.$route.params.status;
    this.requestId = this.$route.params.request_id;

    this.dataStatus.forEach((value) => {
      if (this.status == value.status) {
        this.statusImage = value.image;
        this.statusMessage = value.message;
        this.statusTitle = value.title;
      }
    });
  },
  mounted() {
  },
  methods: {
    goHome() {
      /* STATUS
      0	Pending
      1	Approved
      2	Cancelled
      4	Rejected
      5	Expired
      */
      this.dialog = false;
      this.$router.push('/polizas');
    },
    formatCurrency: formatCurrency
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 13px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>