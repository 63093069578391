<template>
  <v-dialog
      v-model="open"
      max-width="700"
      overlay-color="primary"
  >
    <v-card class="pa-4 pa-md-7">
      <div class=" d-flex justify-space-between pb-3">
        <div class="d-md-flex align-baseline">
          <h3 class="today-medium primary--text">
            PLAN DE PAGOS PARA:
          </h3>
          <h4 class="today-medium pl-md-2" style="font-size: 1.3em; font-weight: normal;">
            {{ formatCurrency(totalValuePolicy) }} a {{ feeNumber }} meses
          </h4>
        </div>
        <div>
          <v-btn
              color="white"
              class="text-capitalize primary--text today-medium"
              elevation="2"
              outlined
              block
              style="border: none; height: 26px"
              @click="open = false"
          >
            SALIR
          </v-btn>
        </div>
      </div>
      <v-simple-table class="table-striped" dense
                      fixed-header
                      height="300px"
      >
        <template v-slot:default class="table-striped">
          <thead>
          <tr>
            <th class="text-center" style="background: #00205B; color: #FFFFFF; border-radius: 10px 0 0 10px;">
              MES
            </th>
            <th class="text-center" style="background: #00205B; color: #FFFFFF; ">
              ABONO A CAPITAL
            </th>
            <th class="text-center" style="background: #00205B; color: #FFFFFF; ">
              INTERÉS
            </th>
            <th class="text-center" style="background: #00205B; color: #FFFFFF; ">
              CUOTA
            </th>
            <th class="text-center" style="background: #00205B; color: #FFFFFF; border-radius: 0 10px 10px 0;">
              SALDO A CAPITAL
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in payPlan"
              :key="item.nro_cuota">
            <td class="text-center">{{ item.nro_cuota }}</td>
            <td class="text-center">{{ formatCurrency(item.abono_capital) }}</td>
            <td class="text-center">{{ formatCurrency(item.interes_corriente) }}</td>
            <td class="text-center">{{ formatCurrency(item.cuota) }}</td>
            <td class="text-center">{{ formatCurrency(item.saldo_final) }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>
<script>
import {formatCurrency} from "@/helpers/constants";

export default {
  name: 'PaymentPlan',
  props: {
    payPlan: Array,
    totalValuePolicy: Number,
    feeNumber: Number,
    dialog: Boolean
  },
  data() {
    return {
      open: this.dialog
    }
  },
  methods: {
    formatCurrency: formatCurrency
  },
  watch: {
    dialog(value) {
      this.open = value;
    },
    open(value) {
      if (!value) {
        this.$emit('close');
      }
    }
  }
}
</script>