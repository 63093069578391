<template>
  <div class="home-page fill-height">

    <v-row class="fill-height" style="margin-top: 0 !important;">
      <left-side-image/>

      <v-col cols="12" md="7">

        <v-row align="center" justify="center" class="pl-4 pr-4">
          <v-col cols="12" md="10">
            <div class="mt-12 primary--text">
              <h1 class="text-center today-regular home-title">
                FINANCIA TU PÓLIZA DE AUTOMÓVIL
              </h1>
            </div>
          </v-col>
          <v-col cols="12" md="10">
            <h2 class="text-center primary--text today-light sub-title" style="font-weight: 100; line-height: 1;">
              Con VARDI Agencia de Seguros, obtén la financiación que necesitas para tu póliza de automóvil de manera
              rápida y sencilla. ¡Protege tu vehículo hoy mismo!
            </h2>
          </v-col>
        </v-row>

        <v-col class="d-flex align-center">
          <v-container>

            <v-row justify="center" class="mt-6">
              <v-col cols="12" md="4" lg="4">

                <v-card
                    class="mx-auto pt-4 home-button"
                    max-width="320"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    :to="{ name: 'SimulateFinancing'}"
                >
                  <v-img max-width="90"
                         class="mb-4 pa-5 home-image mx-auto"
                         contain
                         src="@/assets/img/simular.png"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle">
                    SIMULAR FINANCIACIÓN
                  </v-card-title>
                </v-card>

              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-card
                    class="mx-auto pt-4 home-button"
                    max-width="320"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    @click.stop="dialog = true">
                  <v-img
                      max-width="100"
                      class="mb-4 pa-5 home-image mx-auto"
                      contain
                      src="@/assets/img/financiar.png"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle">
                    FINANCIAR PÓLIZA
                  </v-card-title>

                </v-card>
              </v-col>
              <v-col cols="12" md="4" lg="4">
                <v-card
                    class="mx-auto pt-4 home-button"
                    max-width="300"
                    height="210"
                    hover
                    outlined
                    style="border-color: #00205B !important; border-radius: 12px;"
                    :href="'https://tefio-vardi.bio.credit/'"
                    :target="'_blank'">
                  <v-img
                      max-width="120"
                      class="mb-4 pa-5 home-image mx-auto"
                      contain
                      src="@/assets/img/pagar.png"
                  ></v-img>

                  <v-card-title class="today-medium text-center justify-center px-10 card-subtitle">
                    PAGAR CUOTA
                  </v-card-title>

                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        max-width="400"
        overlay-color="primary"
    >
      <v-card>
        <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium pt-7" style="padding-top: 35px !important;">
          Ingresar información
        </v-card-title>

        <div class="px-14 pb-6 mt-4">
          <v-form
              ref="form"
              lazy-validation
              @submit.prevent="singUp">

            <v-select
                v-model="document_type"
                :items="document_types"
                item-text='value'
                item-value='key'
                :rules="[v => !!v || 'Selecciona el campo']"
                label="Tipo de documento"
                required
                outlined
                dense
                color="primary"
                class="select-field"
            ></v-select>

            <v-text-field
                v-model="document_id"
                :rules="[v => !!v || 'Ingresa la información']"
                type="number"
                label="Número de documento"
                required
                outlined
                dense
                color="primary"
                class="input-field"
            ></v-text-field>

<!--            <v-icon color="primary">mdi-ticket-percent-outline</v-icon>-->
<!--            <a href="#" @click="promoCode = !promoCode" class="today-bold" style="font-size: 0.65rem !important;"-->
<!--               color="primary">Si tienes un código promocional ingrésalo aquí</a>-->
<!--            <v-text-field v-if="promoCode"-->
<!--                          v-model="coupon"-->
<!--                          label="Ingresa el código para redimirlo"-->
<!--                          class="input-field"-->
<!--            ></v-text-field>-->

            <v-checkbox v-model="terms" class="mt-0" color="primary">
              <template v-slot:label>
                <v-tooltip v-slot:activator="{ on, attrs }" bottom>
                  <a v-on="on" @click.stop href="https://vardiseguros.com.co/static/uploads/613b959d-eb39-4cad-b1e4-b9a9344194af.pdf"
                     target="_blank" class="terms-link mt-3 today-bold" style="font-size: 0.65rem !important;">
                    Al ingresar estas aceptando los términos y condiciones, políticas de privacidad y
                    tratamiento de datos personales</a>

                </v-tooltip>
              </template>
            </v-checkbox>
            <v-layout justify-center pt-4>
              <v-card-actions>
                <v-btn
                    style="min-width: 240px"
                    :loading="loading"
                    :disabled="loading || !terms"
                    rounded
                    color="primary"
                    type="submit"
                    class="px-17 text-capitalize vardi-button">
                  CONTINUAR
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
  </div>

</template>

<script>
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";


export default {
  name: 'home-app',
  components: {OnboardingModal, LeftSideImage},
  data() {
    return {
      terms: false,
      document_type: '',
      document_id: '',
      coupon: '',
      dialog: false,
      promoCode: false,
      loading: false,
      settings: null,
      document_types: [],
      snackbar: false,
      timeout: 3000,
      snackbarMessage: '',
      user: null,
      windowHeight: window.innerWidth
    }
  },
  methods: {
    singUp() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.loading = true;

        api.post(constants.endPoints.isRegistered, {
          document_type: this.document_type,
          document_id: this.document_id,
          coupon: this.coupon
        }).then(function (response) {
          this.loading = false;
          let exist_user = response.data.data.exist_user;
          let exist_sics = response.data.data.exist_sics;

          let user = {
            document_type: this.document_type,
            document_id: this.document_id,
            coupon: this.coupon
          };

          locals.setLocal('user', JSON.stringify(user));

          if (exist_user) {
            user.login = true;
            locals.setLocal('user', JSON.stringify(user));

            this.$router.push('/preparate-para-selfie');
          } else {
            if (exist_sics.status) {
              this.$router.push('/registro-de-documento');
            } else {
              this.snackbarMessage = exist_sics.message;
              this.snackbar = true;
            }

          }
        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

        }.bind(this))
      }

    },
    onResize() {
      this.windowHeight = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  created() {
    api.get(constants.endPoints.settings)
        .then(response => {
          locals.setLocal('settings', JSON.stringify(response.data.data));
          this.settings = locals.getLocal('settings');
          this.settings.config.forEach((value) => {
            if (value.key == 'document_types') {
              this.document_types = value.value;
            }
          });
        })
    this.user = locals.getLocal('user');
    if (this.user != null) {
      if (this.user.login && this.user.access_token) {
        this.$router.push('/polizas')
      }
    }
  }
}
</script>

<style scoped>
.v-tooltip__content {
  display: none !important;
}
.home-button:hover {
  background-color: #00205B !important;
}

.home-button:hover .v-card__title {
  color: #FFFFFF !important;
}

.home-button:hover .home-image {
  filter: brightness(0) invert(1);
}


.terms-link {
  display: block;
}

.v-card__title {
  word-break: inherit;
}

.home-title {
  font-size: 2.5em;
  line-height: 1;
}

.card-subtitle {
  font-size: 22px;
  line-height: 1;
  color: #00205B;
}

.home-image {
  height: 100px;
}

@media (max-width: 600px) {
  .home-title {
    font-size: 2em;
    line-height: 1;
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 1;
  }

  .home-image {
    height: 100px;
  }
}
</style>
