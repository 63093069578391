<template>
  <v-text-field
      @blur="onBlur"
      v-model="model"
      ref="inputRef"
      type="text"
      :label="label"
      :disabled="disabled"
      required
      prefix="$"
      single-line
      color="primary"
      class="text-h4 my-currency-input"
      :step="0.01"
      hide-spin-buttons
      :rules="rules"/>
</template>

<script>
import {CurrencyInputOptions, useCurrencyInput} from "vue-currency-input";
import {watch} from "@vue/composition-api";

export default {
  name: "CurrencyInput",
  props: {
    value: Number,
    options: CurrencyInputOptions,
    rules: {
      type: Array,
      default: () => []
    },
    label: String,
    disabled: false,
  },
  data() {
    return {
      model: this.value
    }
  },
  methods: {
    onBlur() {
      this.$emit('blur', this.model.replace(/[^0-9.]/g, ''));
    }
  },
  setup(props) {
    const {inputRef, setValue, formattedValue} = useCurrencyInput(props.options);
    watch(() => props.value, () => setValue(props.value));
    return {inputRef};
  },
};
</script>
<style lang="scss">
.my-currency-input {
  input {
    max-height: 39px !important;
    margin-left: -26px !important;
    text-align: center;
  }

  .v-text-field__prefix {
    color: #00205B !important;
    font-weight: bold !important;
  }
}

</style>